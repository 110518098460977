import React from 'react'
import FacebookLogo from "../content/static/socialMedia/logo-facebook.svg";
import InstagramLogo from "../content/static/socialMedia/logo-instagram.svg";
import TikTokLogo from "../content/static/socialMedia/logo-tiktok.svg";
import WhatappLogo from "../content/static/socialMedia/logo-whatsapp.svg";
import MailLogo from "../content/static/socialMedia/mail-outline.svg";

const SocialMediaLinks = () => {
  return (

    <>
      <div className="socialMediaItem desktop">
        <a className="socialMediaBackground whatsapp" href="https://wa.me/56976191579" target="_blank" rel="noreferrer"><img className="socialMediaLogos" src={WhatappLogo} alt="Whatsapp" /></a>
        <a className="socialMediaBackground instagram" href="https://www.instagram.com/gonzalochaconlarrain" target="_blank" rel="noreferrer"><img className="socialMediaLogos" src={InstagramLogo} alt="Instagram" /></a>
        <a className="socialMediaBackground facebook" href="https://www.facebook.com/gonzalochacon.vitacura" target="_blank" rel="noreferrer"><img className="socialMediaLogos" src={FacebookLogo} alt="Facebook" /></a>
        <a className="socialMediaBackground tiktok" href="https://www.tiktok.com/@gonzalochaconl" target="_blank" rel="noreferrer"><img className="socialMediaLogos" src={TikTokLogo} alt="TikTok" /></a>
        <a className="socialMediaBackground mail" href="mailto:contacto@gonzalochacon.cl" target="_blank" rel="noreferrer"><img className="socialMediaLogos" src={MailLogo} alt="Mail" /></a>
      </div>

      <div className="socialMediaItem mobile">
        <a className="socialMediaBackground whatsapp" href="https://wa.me/56976191579" target="_blank" rel="noreferrer"><img className="socialMediaLogos" src={WhatappLogo} alt="Whatsapp" /></a>
        <a className="socialMediaBackground instagram" href="https://www.instagram.com/gonzalochaconlarrain" target="_blank" rel="noreferrer"><img className="socialMediaLogos" src={InstagramLogo} alt="Instagram" /></a>
        <a className="socialMediaBackground facebook" href="https://www.facebook.com/gonzalochacon.vitacura" target="_blank" rel="noreferrer"><img className="socialMediaLogos" src={FacebookLogo} alt="Facebook" /></a>
        <a className="socialMediaBackground tiktok" href="https://www.tiktok.com/@gonzalochaconl" target="_blank" rel="noreferrer"><img className="socialMediaLogos" src={TikTokLogo} alt="TikTok" /></a>
        <a className="socialMediaBackground mail" href="mailto:contacto@gonzalochacon.cl" target="_blank" rel="noreferrer"><img className="socialMediaLogos" src={MailLogo} alt="Mail" /></a>
      </div>
    </>

  )
}

export default SocialMediaLinks;