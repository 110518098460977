import React from 'react'
import { Link } from 'react-router-dom';

const Propuestas = () => {
    return (
        <div className="row my-5 py-5">
            <h2 className="subtitle text-center">PROPUESTAS</h2>
            <div className="col-md-4 col-12 Propuesta my-5">
                <h3 className="roundSubtitle">1</h3>
                <p className="my-5 text-center">Implementación de cámaras de vigilancia en puntos estratégicos de la comuna.</p>
            </div>
            <div className="col-md-4 col-12 Propuesta my-5">
                <h3 className="roundSubtitle">2</h3>
                <p className="my-5 text-center">Creación de patrullas comunitarias para reforzar la seguridad.</p>
            </div>
            <div className="col-md-4 col-12 Propuesta my-5">
                <h3 className="roundSubtitle">3</h3>
                <p className="my-5 text-center">Fortalecimiento de la iluminación pública en zonas vulnerables.</p>
            </div>
            <h5 className="d-flex justify-content-center">Conoce más sobre mis propuestas haciendo click <Link className="noDecoration2 ms-2" to="Mis_Propuestas">aquí</Link></h5>
        </div>
    )
}

export default Propuestas