import React from 'react'
import { Link } from 'react-router-dom';

const Conoceme = () => {
  return (
    <div className="row my-5">
      <div className="col-md-6 col-12 conocemeTexto">
        <h2 className="subtitle">¿QUIÉN ES GONZALO CHACÓN LARRAÍN?</h2>
        <p>Gonzalo Chacón Larraín, tiene una destacada trayectoria en el servicio público, habiendo ejercido roles como intendente, gobernador y Seremi. Su amplia experiencia en la administración pública lo ha preparado para enfrentar los desafíos de la comuna de Vitacura. Ahora, como candidato a concejal, se compromete a combatir la inseguridad, implementando medidas efectivas para proteger a los vecinos y mejorar la calidad de vida de los vecinos de Vitacura.</p>
        <div className="d-flex justify-content-center mt-2">
          <p>Si quieres saber más de mí, haz click <Link className="noDecoration2" to="/Gonzalo_Chacon_Larrain">aquí</Link></p>
        </div>
      </div>
      <div className="col-md-6 col-12 my-auto">
        <iframe className="frameYoutube" src="https://www.youtube.com/embed/b2txmnpcNO0?si=u0pe4o_vgHUOb3QP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
    </div>
  )
}

export default Conoceme;