import React from 'react';
import { Link } from 'react-router-dom';

import GonzaloEnTerreno from '../content/static/photos/gonzaloTerreno.jpeg';

const GonzaloChacon = () => {
    return (
        <div className="row">
            <div className="col-md-4 col-12">
                <img className="photoConoceme ms-3 mt-3" src={GonzaloEnTerreno} alt="Gonzalo en Terreno" />
            </div>
            <div className="col-md-7 col-12">
                <p className="my-4 px-5">Hola, soy Gonzalo Andrés Chacón Larraín, abogado de 43 años, casado, padre de dos maravillosos hijos, y vivimos en Vitacura, comuna en la que nací y me crié. Aquí también residen mis abuelos y mis padres, y aspiro a que Vitacura sea una comuna más accesible y segura, tanto para ellos como para todos los vecinos.</p>
                <p className="my-4 px-5">Desde niño, he tenido una fuerte vocación de servicio público. Creo que es esencial mejorar la calidad de vida de nuestros vecinos, crear espacios comunes y hacer de Vitacura un lugar más inclusivo y familiar.</p>
                <p className="my-4 px-5">Mi experiencia como gobernador e intendente, en los gobiernos del presidente Sebastián Piñera, me ha dado la convicción de que en Vitacura aún hay mucho por hacer. Nuestra comuna, que solía destacarse por su tranquilidad y sus parques, hoy enfrenta serios problemas de seguridad, al igual que el resto del país. Además, no hemos sido lo suficientemente amigables con nuestros adultos mayores, quienes merecen más apoyo por todo lo que han contribuido. Necesitamos más plazas pensadas para todas las edades, así también como para nuestras mascotas.</p>
                <p className="my-4 px-5">Como concejal, me comprometo a fiscalizar el buen uso de los fondos municipales, asegurar que se cumplan las promesas y ser la voz de los vecinos en la búsqueda de soluciones para la inseguridad y otros problemas que nos afectan.</p>
                <h4 className="d-flex justify-content-center my-5"><Link className="noDecoration2" to="/"><strong>Volver</strong></Link></h4>
            </div>
            <div className="col-md-1 col-12"></div>
        </div>
    );
}

export default GonzaloChacon;