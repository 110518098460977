import React from 'react'

const JsmiFooter = () => {
  return (
    <div className="blackBackground">
        <p className="jsmiFooterText">Página programada por <a className="noDecoration" href="https://www.santamariai.cl" target="_blank" rel="noreferrer"><strong>Jerónimo Santa María Illanes</strong></a> y Diseñada por Carla Castillo</p>
    </div>
  )
}

export default JsmiFooter