import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './views/Home';
import NotFound from './views/NotFound';

import SocialMediaLinks from './components/SocialMediaLinks';
import Footer from './components/Footer';
import JsmiFooter from './components/JsmiFooter';
import GonzaloChacon from './views/GonzaloChacon';
import MisPropuestas from './views/Propuestas';

const App = () => {

  const BaseURL = "/";

  return (
    <div className="App">
      <h1 className="principalHeader">Gonzalo Chacón Larraín</h1>
      <BrowserRouter>
        <SocialMediaLinks />
        <Routes>
          <Route exact path={BaseURL} element={<Home />} />
          <Route exact path={BaseURL+ "/Gonzalo_Chacon_Larrain"} element={<GonzaloChacon />} />
          <Route exact path={BaseURL+ "/Mis_Propuestas"} element={<MisPropuestas />} />
          <Route exact path='*' element={<NotFound />} />
        </Routes>
        <Footer />
        <JsmiFooter />
      </BrowserRouter>
    </div>
  );
}

export default App;
