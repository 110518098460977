import React from 'react';

const Doname = () => {
  return (
    <div className="text-center my-5 py-5 colorDonaciones">
        <h4>Si quieres donar a mi campaña haz click <a className="btn btn-warning" href="https://aportes.servel.cl/servel-aportes/inicio.xhtml?fbclid=PAZXh0bgNhZW0CMTEAAabrBey8FGx3SzqlQmj_bARhKgrqqGpUaQ_Tz7uyVaPGaSnlRxeNyTU0v3U_aem_6A-FRl60NbJeXeICzFZ3JA" target="_blank" rel="noreferrer">AQUÍ</a></h4>
        <p>Te redirigirá directo al sistema de donaciones del Servel</p>
    </div>
  );
}

export default Doname;