import React from 'react';
import { Link } from 'react-router-dom';

import FotoPropuestas from '../content/static/photos/fotoPropuestas.png';

const MisPropuestas = () => {
    return (
        <div className="row">
            <div className="col-md-4 col-12 text-center">
                <img className="photoConoceme my-5" src={ FotoPropuestas } alt="Mis Propuestas" />
            </div>
            <div className="col-md-7 col-12">
                <h2 className="my-4 text-center"><strong>Mis Propuestas</strong></h2>
                <p className="my-4 px-5 justify-text">Queridos Vecinos de Vitacura. Mi compromiso con ustedes es único y claro: vengo a mejorar la calidad de vida y preocuparme por la seguridad de ustedes y de nuestra querida comuna para que siga siendo un referente en bienestar y seguridad.</p>
                <p className="my-4 px-5 justify-text">Es por ello que quiero compartir con ustedes algunas de las propuestas concretas que tengo para llevar adelante, además por supuesto, de ser un firme fiscalizador en la comuna. Estas propuestas están centradas en nuestras necesidades reales y en los desafíos que enfrentamos.</p>
                <ol className='my-4 px-5'>
                    <li className="my-3 ms-5 justify-text">Pide Delivery Seguro: Hoy en día, el delivery se ha vuelto parte esencial de nuestras vidas, pero con ello también vienen riesgos. Mi propuesta es implementar un sistema de "delivery seguro" para Vitacura, donde las entregas sean monitoreadas y los repartidores verificados, en coordinación con las principales aplicaciones de delivery y nuestra municipalidad. Así, podrán recibir sus pedidos con tranquilidad, sabiendo que contamos con medidas de seguridad claras para todos.</li>
                    <li className="my-3 ms-5 justify-text">Programa Taxi Seguro: Quiero crear un programa de taxis seguros que funcione las 24 horas, donde los conductores sean seleccionados y validados por la municipalidad, para que puedan moverse por nuestra comuna con total confianza. Un servicio pensado especialmente para nuestras familias y adultos mayores, con taxis de confianza que cuenten con el respaldo de la comunidad. Quiero destacar, que actualmente soy el abogado que patrocina la defensa de tantos vecinos que se han visto lamentablemente afectados por delincuentes que se hacen pasar por taxistas y conductores de aplicaciones, y tengo el interés de cortar de raíz con este problema de seguridad que actualmente tenemos.</li>
                    <li className="my-3 ms-5 justify-text">Aumento del subsidio para el pago de contribuciones de los adultos mayores: Nuestros adultos mayores han sido, son y seguirán siendo el pilar de nuestra comuna. Por eso, trabajaré para aumentar el subsidio en el pago de contribuciones para quienes tienen ingresos limitados. No es justo que quienes han trabajado toda su vida vean comprometido su bienestar económico por cargas tributarias desproporcionadas. Mi compromiso es asegurar que nuestros mayores puedan vivir con la dignidad que merecen.</li>
                    <li className="my-3 ms-5 justify-text">Un sheriff nocturno: La seguridad es una prioridad, y por eso quiero implementar la figura del "sheriff nocturno", un líder de seguridad que, junto a Carabineros y seguridad privada, garantice, coordine y planifique la tranquilidad en nuestras calles durante la noche. Este "sheriff" estará equipado con tecnología avanzada y coordinará operativos preventivos para reducir robos y actos delictivos. Así, ustedes podrán dormir tranquilos sabiendo que hay un equipo vigilando por su bienestar.</li>
                    <li className="my-3 ms-5 justify-text">Plan de contingencia para las externalidades del metro y el mall: Sabemos que la llegada del metro y la expansión del mall traerán beneficios, pero también desafíos en términos de congestión, seguridad y ruido. Mi propuesta es desarrollar un plan de contingencia para minimizar estos impactos. Soluciones como la gestión inteligente del tráfico, la instalación de semáforos adecuados y la creación de zonas seguras para el flujo peatonal y vehicular serán parte de esta estrategia. Además, implementaremos medidas que mitiguen el ruido y otros efectos negativos, para que la modernización no comprometa la calidad de vida en nuestra comuna.</li>
                </ol>
                <p className="my-4 px-5 justify-text">Como candidato a concejal, mi prioridad es cuidar de Vitacura y de cada uno de ustedes. Estoy aquí para escuchar, trabajar en equipo y construir un futuro más seguro, más justo y más próspero para todos.</p>
                <p className="my-4 px-5 justify-text">¡Gracias por su apoyo y confianza!</p>
                <h4 className="d-flex justify-content-center my-5"><Link className="noDecoration2" to="/"><strong>Volver</strong></Link></h4>
            </div>
            <div className="col-md-1 col-12"></div>
        </div>
    );
}

export default MisPropuestas;