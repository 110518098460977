import React from 'react';
import LogoGonzalo from '../content/static/photos/logo.png';

const Footer = () => {
  return (
    <div className="d-flex justify-content-center my-5">
        <img className="logoChacon" src={LogoGonzalo} alt="Logo" />
    </div>
  )
}

export default Footer